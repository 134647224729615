<template>
<div class="align">
  <div class="contentBlock">
    <div class="title">
      <h1>Акция действует в период с 05.07.2024г. до 30.09.2024г. включительно в магазинах KARI и Kari KIDS</h1>
    </div>
    <div class="body">
      <h2>Как воспользоваться промокодом:</h2>
      <ul>
        <li>- на сайте <a href="https://kari.com/?utm_source=finmoll&utm_medium=cpc&utm_campaign=mp&utm_term=1" target="_blank">kari.com</a> введите префикс fm, затем номер действующего договора займа в поле "промокоды" при оформлении заказа. Ниже указан Ваш промокод.</li>
        <li>- в магазинах KARI и Kari KIDS назовите кассиру префикс fm и номер действующего договора займа в ООО МКК «ФИНМОЛЛ». Ниже указан Ваш промокод.</li>
        <li>- быть участником бонусной программы лояльности Kari CLUB</li>
      </ul>
      <h2>Как стать участником бонусной программы Kari CLUB:</h2>
      <ul>
        <li>- через мобильное приложение KARI</li>
        <li>- в личном кабинете на сайте <a href="https://kari.com/?utm_source=finmoll&utm_medium=cpc&utm_campaign=mp&utm_term=2" target="_blank">kari.com</a></li>
      </ul>
      <div v-if="code.length > 0">
        <h2>Ваш промокод</h2>
        <p>{{code}}</p>
      </div>
      <div v-else>
        <p>Для получения промокода, вам нужно обновить приложение</p>
      </div>
      <p>С условиями акции можно ознакомиться <a href="pdf/karipromocode240702.pdf" target="_blank">здесь</a></p>
    </div>
    <div class="layoutFooter">
      <img src="https://cdn.api.kari.com/www/16697308190d27788b7515d042b6e152581d7ea994.svg" alt="">
      <img src="https://cdn.api.kari.com/www/1669730949a82be07ed1a84c5c66937b3f35fea32c.svg" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "kariPromo",
  data(){
    return {
      code:'',
    }
  },
  mounted() {
    try {
      this.code = this.$route.query.id !== undefined ? this.$route.query.id : '';
    } catch (e){
      console.log(e);
    }
  }

}
</script>

<style scoped>
.layoutFooter{display: flex;justify-content: space-around}
</style>